<template>
  <div class="u-w-full" ref="prefectureModal">
    <div class="c-panel__small">
      <div
        class="p-prefecture-modal-v2__selector"
        v-on:click="isModalActive = true"
      >
        <span>{{ selectedPrefectureNames }}</span>
      </div>
      <div
        v-if="updateFlag && !isValid"
        class="p-entry-form__input__error text-center prefecture_error"
      >
        ＊「都道府県」を選択してください
      </div>
    </div>
    <div class="p-multiple-workplace-modal" v-show="isModalActive">
      <div class="p-multiple-workplace-modal__overlay js-modal-close"></div>
      <div class="p-multiple-workplace-modal__inner">
        <div class="p-multiple-workplace-modal__header">
          <img
            class="p-multiple-workplace-modal__icon"
            width="20"
            height="20"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABaCAMAAAAsLeDlAAAAnFBMVEUAAAAQmlUfqVkRmVURmlUWn1sRmVUQmlUQmlUZnlkRmlUSm1YSnlcQmVQRmVUQmlUQmlUUnFgQmlURmlUQmlUQmlQRmlUSmlYQmlURmlUQmlYQm1YSnFcQmVQRmlURm1QQmlURmlURmlURmlUTm1YRnFcWnVgXoF8TnFYQmlQRmlURmlUQmlURm1YSm1YRmlUQmlUUnVgSmlQQmVTh9Q1RAAAAM3RSTlMA+wj38w7myOoUkDgc3NbOXhi5ppx+eFOtWU88Ke7Cb6GVgmVBLiEKNOC0h71LRphrJXIW5eP7AAAEMUlEQVRYw6VYaYOiMAxNAQUExVs8kfEcb4f//99207hbLNAp9X1iaqcmLy8vFVCgcUvSS6fpMMdru+vxpAcmOJ5dlkno3K8W1EJv3MnK4aVH0Mb+zjIF+lvQgj/8f4wXn7vz3d4PF8FkvBZBrnSieiz/7U4WcsLTgU2fsbQBaoSrVyyjfXkhNxFtaKrzuy5pV3JQ7HEpqJHinDFtOR9Aia6XIU6V21oZIlrAb2isM4TrQyno0/gAGvhhXKB+dTwj0MONsxmVFG+EHzgT0MWTy+pSaJkZLttH0EeDCyGVu4JHOoE62Hsl/+NW8BOcY/fb9qJTOiv2/ZwhG2/KTfCcAUhY3JuZgB1fC4LiPZxvIgdVYUmdNSx4wCqQKy0lN0A9SzpM7KwEa+nbsHTN/2sL3PIFeVjDjNBuJZP5bPPlZoQofKcJ15J8QN/WW2lXREtrJzJ9EGHNo9QOIqQ9cvGAPPqUR/ge5djhDvO2+sR/3tDzFx0qc2hPQcauzbN768YBrtDj99/HcaGqXlBmw25BJ0dc4YXa4lOYJwgVy+blhs5jmsuFO//L41Jo3w2UY8ElJ7thBx8ipFpWZx+qkMgNtseFHoBPOQr8YGI7qIL1LX8PLkzJP5r59ZVEZ1mDsbyhoXZTSjHO04nCmKl8iPEIBDZIMp13lr7QVvp2Xwp5jjlRJl2pZn218Ut16yHLDUBhbEHg/vfvIagwwdbML6BP7AFbMd+HJ3ICBQJkO7+AAt4BquYpmW6inh+UikCbi52JBjGNqMPZWWJcH3FE7FBcplUTZD95q80+0tEBOfPhInRkqOwQD7K4w40Me02Qv6ReO8En3f+gXrtRpxj7EQ2S1suPeuYOSTLqvlRwBXPPPrCXEmOKzHCKEDeOhb5EQjWca2RoMdkJhWY2aan3SYgutanR7KexyPz8YDK4jSBSvDeJWw2apMn9CKxlzr9WL7rq39ioVHYjZzCMQta/Qwo1CvuymhLd0q2WFW+1guo3y//C/m2AAU7vDRMypVGr7TvrSkbt+FAbFxRIfiG0aXDXxI0CkmVlh1ATUVHKPZu0pA/SELl3wc2uUAf+UnSHgNVG3iyogWG5uV8zfQmIXw9phbZYALo4YApemYr3DtZAO7mUmK4sQquOhGLFUM9uehVrYmJVvRBiPZdasuyrf0tP8WNXTZNQ3Vqx4a5H05ZhXVR3HyuiNlSCXhI4i9/32Go1NTqVlZdfbTTD31wxS/V4jBS+2xIvV9QYqDeO+djUcVOLbhyKix8SrYNeu1oEVyw824Ienl5WYeFbmwqmi4DvHxfXHVz/qTMe6KTiObWHzYwVT5o7xF09TJn8jmvrZHRhMDqpJWK0zc6hk4RXbJjZOYKnfoMah+Izw42z0pmFQZzRsDJF4GUC7AHmeLbFzW8Cn8CPM0J0hA8RDCKnferCb/gDLa4HzyP5ThEAAAAASUVORK5CYII="
          />
          <div class="p-multiple-workplace-modal__title">
            希望勤務地（複数可）
          </div>
        </div>
        <div class="p-multiple-workplace-modal__body">
          <div
            v-for="(areaWithPrefectures, i) in areasWithPrefectures"
            :key="`area-${i}`"
            class="p-multiple-workplace-modal__container"
          >
            <div class="p-multiple-workplace-modal__container">
              <div class="p-multiple-workplace-modal__item">
                <div class="p-multiple-workplace-modal__parent">
                  <!-- 全国の場合 -->
                  <label
                    class="p-multiple-workplace__label"
                    v-if="areaWithPrefectures.id === nationalAreaId"
                  >
                    <input
                      type="checkbox"
                      class="p-multiple-workplace__checkbox"
                      :for="'area' + areaWithPrefectures.id"
                      :value="areaWithPrefectures.prefectures[0].id"
                      name="applicant[desired_workplace_id][]"
                      v-model="selectedPrefectureIds"
                    />
                    {{ areaWithPrefectures.name }}
                  </label>
                  <!-- 全国以外の場合 -->
                  <label
                    class="p-multiple-workplace__label"
                    v-if="areaWithPrefectures.id !== nationalAreaId"
                  >
                    <input
                      type="checkbox"
                      class="p-multiple-workplace__checkbox"
                      :for="'area' + areaWithPrefectures.id"
                      :checked="allChildSelected(areaWithPrefectures)"
                      @change="toggleAll(areaWithPrefectures)"
                    />
                    {{ areaWithPrefectures.name }}
                  </label>
                </div>
                <div
                  class="p-multiple-workplace-modal__child"
                  v-if="
                    areaWithPrefectures.prefectures &&
                    areaWithPrefectures.id !== nationalAreaId
                  "
                >
                  <label
                    v-for="(prefecture, i) in areaWithPrefectures.prefectures"
                    :for="'prefecture' + prefecture.id"
                    class="p-multiple-workplace__label"
                    :key="`prefecture-${i}`"
                  >
                    <input
                      :id="'prefecture' + prefecture.id"
                      type="checkbox"
                      :value="prefecture.id"
                      name="applicant[desired_workplace_id][]"
                      class="p-multiple-workplace__checkbox"
                      v-model="selectedPrefectureIds"
                    />
                    {{ prefecture.name }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="p-multiple-workplace-modal__footer">
          <button
            type="button"
            class="p-multiple-workplace__button -border-green"
            v-on:click="isModalActive = false"
          >
            閉じる
          </button>
        </div>
      </div>
    </div>
    <div
      class="v-prefecture-modal__background"
      v-show="isModalActive"
      v-on:click="isModalActive = false"
    ></div>
  </div>
</template>
<script>
import axios from 'axios';

export default {
  data: () => {
    return {
      isModalActive: false,
      prefectures: [],
      areasWithPrefectures: [],
      selectedPrefectureIds: [],
      updateFlag: false,
      nationalAreaId: 9,
    };
  },
  computed: {
    selectedPrefectureNames() {
      if (this.selectedPrefectureIds.length == 0) {
        return '都道府県を選択';
      }

      return this.prefectures
        .filter((p) => this.selectedPrefectureIds.includes(p.id))
        .map((p) => p.name)
        .join('、');
    },
    isValid() {
      return this.selectedPrefectureIds.length > 0;
    },
  },
  methods: {
    // バリデーション時に高さが変わるため、高さを再計算する
    updateSlideHeight() {
      const prefectureModal = this.$refs.prefectureModal;
      const parent = prefectureModal.closest('.p-horizontal-slide__step');
      if (!parent) return;

      const stepHeight = parent.offsetHeight;
      const slide = document.querySelector('.p-horizontal-slide');

      slide.style.height = `${stepHeight + 10}px`;
    },
    /**
     * 配下のすべてのチェックボックスが選択されているかを判定
     */
    allChildSelected(area) {
      if (!area.prefectures || area.prefectures.length === 0) return false;
      return area.prefectures.every((prefecture) =>
        this.selectedPrefectureIds.includes(prefecture.id)
      );
    },
    /**
     * 親のチェックボックスをクリックした際に、配下のチェックボックスを全て選択/解除
     */
    toggleAll(area) {
      const childPrefectureIds = area.prefectures.map(
        (prefecture) => prefecture.id
      );
      const allSelected = this.allChildSelected(area);

      if (allSelected) {
        // 全て選択済みの場合、すべて解除
        this.selectedPrefectureIds = this.selectedPrefectureIds.filter(
          (id) => !childPrefectureIds.includes(id)
        );
      } else {
        // 選択されていないものを追加
        this.selectedPrefectureIds = [
          ...new Set([...this.selectedPrefectureIds, ...childPrefectureIds]),
        ];
      }
    },
  },
  watch: {
    selectedPrefectureIds() {
      setTimeout(() => this.updateSlideHeight(), 10);

      if (this.updateFlag) return;
      this.updateFlag = true;
    },
  },
  beforeCreate() {
    const vm = this;
    axios.get('/api/v2/areas_with_prefectures').then((response) => {
      vm.$set(vm, 'areasWithPrefectures', response.data);
    });
    axios.get('/api/prefectures?order=position').then((response) => {
      vm.$set(vm, 'prefectures', response.data);
    });
  },
};
</script>
