<template>
  <div class="u-w-full" ref="prefectureModal">
    <div class="c-panel__small">
      <div
        class="p-prefecture-modal-v2__selector"
        v-on:click="isModalActive = true"
      >
        <span>{{ selectedPrefectureNames }}</span>
      </div>
      <div
        v-if="updateFlag && !isValid"
        class="p-entry-form__input__error text-center prefecture_error"
      >
        ＊「都道府県」を選択してください
      </div>
    </div>
    <div class="v-modal p-prefecture-modal-v2" v-show="isModalActive">
      <div class="v-modal__header">希望勤務地（複数可）</div>
      <div class="v-modal__body">
        <div
          v-for="(prefecture, i) in prefectures"
          :key="`prefecture-${i}`"
          class="v-modal__item"
        >
          <label
            :for="'prefecture' + prefecture.id"
            class="p-client-scout__radio-label u-color-black js-label"
          >
            <input
              :id="'prefecture' + prefecture.id"
              type="checkbox"
              :value="prefecture.id"
              name="applicant[desired_workplace_id][]"
              class="c-checkbox -v2 -bd-1 checkbox-item"
              v-model="selectedPrefectureIds"
            />
            {{ prefecture.name }}
          </label>
        </div>
      </div>
      <div class="v-modal__footer">
        <button
          type="button"
          class="c-button -border-green"
          v-on:click="isModalActive = false"
        >
          閉じる
        </button>
      </div>
    </div>
    <div
      class="v-modal__background"
      v-show="isModalActive"
      v-on:click="isModalActive = false"
    ></div>
  </div>
</template>
<script>
import axios from 'axios';

export default {
  data: () => {
    return {
      isModalActive: false,
      prefectures: [],
      selectedPrefectureIds: [],
      updateFlag: false,
    };
  },
  computed: {
    selectedPrefectureNames() {
      if (this.selectedPrefectureIds.length == 0) {
        return '都道府県を選択';
      }

      return this.prefectures
        .filter((p) => this.selectedPrefectureIds.includes(p.id))
        .map((p) => p.name)
        .join('、');
    },
    isValid() {
      return this.selectedPrefectureIds.length > 0;
    },
  },
  methods: {
    // バリデーション時に高さが変わるため、高さを再計算する
    updateSlideHeight() {
      const prefectureModal = this.$refs.prefectureModal;
      const parent = prefectureModal.closest('.p-horizontal-slide__step');
      if (!parent) return;

      const stepHeight = parent.offsetHeight;
      const slide = document.querySelector('.p-horizontal-slide');

      slide.style.height = `${stepHeight + 10}px`;
    },
  },
  watch: {
    selectedPrefectureIds() {
      setTimeout(() => this.updateSlideHeight(), 10);

      if (this.updateFlag) return;
      this.updateFlag = true;
    },
  },
  beforeCreate() {
    const vm = this;
    axios.get('/api/prefectures?order=position').then((response) => {
      vm.$set(vm, 'prefectures', response.data);
    });
  },
};
</script>
