import Utils from '../utils';
import { ERROR_MESSAGES } from '../constants';
import PrefectureModal from '../components/prefecture_modal.vue';
import PrefectureMultiModal from '../components/prefecture_multi_modal.vue';

export default {
  data() {
    return {
      isMounted: false,
      inputItems: {
        licenses: [],
        experiences: [],
        prefecture: '',
        birthYear: '',
        name: '',
        tel: '',
        email: '',
        terms: true,
      },
      errorMessages: {
        license: '',
        experience: '',
        prefecture: '',
        birthYear: '',
        name: '',
        tel: '',
        email: '',
        terms: '',
      },
      step: 1, // 横ステップで使用
      selectedPrefectureDone: false,
      selectedBirthYearDone: false,
      params: [],
    };
  },
  components: {
    PrefectureModal,
    PrefectureMultiModal,
  },
  mounted() {
    this.isMounted = true;
    const params = this.parseParams();
    this.params = params;

    if (params.ref == 'experience_top' || params.ref == 'experience_job') {
      this.$set(this.inputItems, 'experiences', params.experiences);
    }
  },
  computed: {
    isLicensesValid() {
      return this.inputItems.licenses.length >= 1;
    },
    isExperiencesValid() {
      return this.inputItems.experiences.length >= 1;
    },
    isPrefecturesValid() {
      if (!this.isMounted) {
        return false;
      }

      if (this.$refs.prefectures == undefined) {
        return this.inputItems.prefecture !== '';
      }

      return this.$refs.prefectures.isValid;
    },
    isBirthyearValid() {
      return this.inputItems.birthYear !== '';
    },
    isTelValid() {
      this.inputItems.tel = Utils.zenkakuHankaku(this.inputItems.tel);
      return /^\d{10,11}$/.test(this.inputItems.tel);
    },
    isEmailValid() {
      const VALID_EMAIL_REGEX = /^[\w+\-.]+@[a-z\d\-.]+\.[a-z]+$/i;
      this.inputItems.email = Utils.zenkakuHankaku(this.inputItems.email);
      return VALID_EMAIL_REGEX.test(this.inputItems.email);
    },
    isNameValid() {
      this.inputItems.name = Utils.zenkakuHankaku(this.inputItems.name);
      return this.inputItems.name.length > 1;
    },
    isTermsValid() {
      return this.inputItems.terms;
    },
    isAllValid() {
      return (
        this.isLicensesValid &&
        this.isExperiencesValid &&
        this.isPrefecturesValid &&
        this.isBirthyearValid &&
        this.isTelValid &&
        this.isNameValid &&
        this.isEmailValid &&
        this.isTermsValid
      );
    },
  },
  methods: {
    updateShowJobCard() {
      const jobCard = document.querySelector('#js-job_card');
      if (!jobCard) return;
      // step2以降は非表示のため `.u-dis-none`クラスを追加する
      if (this.step > 1) {
        jobCard.classList.add('u-dis-none');
      } else {
        jobCard.classList.remove('u-dis-none');
      }
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    parseParams() {
      const search = window.location.search.substring(1);
      if (search.length <= 0) {
        return {};
      }

      const params = JSON.parse(
        `{"${decodeURI(search)
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"')}"}`
      );
      Object.keys(params).forEach((key) => {
        params[key] = params[key].split(',');
      });
      return params;
    },
    handleLicenses() {
      this.errorMessages.license = this.isLicensesValid
        ? ''
        : ERROR_MESSAGES.LICENSE;
    },
    handleExperiences() {
      this.errorMessages.experience = this.isExperiencesValid
        ? ''
        : ERROR_MESSAGES.EXPERIENCE;
    },
    selectPrefectureChangeHandler() {
      if (this.isPrefecturesValid) {
        this.errorMessages.prefecture = '';

        if (!this.selectedPrefectureDone) {
          this.selectedPrefectureDone = !this.selectedPrefectureDone;
        }
      } else {
        this.errorMessages.prefecture = ERROR_MESSAGES.PREFECTURE;
      }
    },
    selectChangeHandler() {
      if (this.isBirthyearValid) {
        this.errorMessages.birthYear = '';

        if (!this.selectedBirthYearDone) {
          this.selectedBirthYearDone = !this.selectedBirthYearDone;
        }
      } else {
        this.errorMessages.birthYear = ERROR_MESSAGES.BIRTHYEAR;
      }
    },
    btnClickHandler(nextStep) {
      this.step = nextStep.replace('step', '');
    },
    handleNameInput() {
      this.errorMessages.name = this.isNameValid ? '' : ERROR_MESSAGES.NAME;
    },
    handleTelInput() {
      this.errorMessages.tel = this.isTelValid ? '' : ERROR_MESSAGES.TEL;
    },
    handleEmailInput() {
      this.errorMessages.email = this.isEmailValid ? '' : ERROR_MESSAGES.EMAIL;
    },
    handleTerms() {
      this.errorMessages.terms = this.isTermsValid ? '' : ERROR_MESSAGES.TERMS;
    },
    handleSubmit() {
      if (this.isAllValid) {
        const form = document.getElementById('js-entry_member_form');
        const submitButton = document.getElementById('js-submit_button');
        submitButton.textContent = '送信中...';
        submitButton.disabled = true;
        // submit
        form.submit();
      } else {
        const errors = [];
        Object.keys(this.errorMessages).forEach((key) => {
          if (errors.length >= 1 || key === 'terms') {
            return;
          }
          if (this.errorMessages[key] !== '') {
            errors.push(Utils.camelToSnake(key));
          }
        });
        if (errors.length > 0) {
          const nextPos = $(`.c-panel__item#${errors[0]}`).offset().top;
          $('html, body').animate({ scrollTop: nextPos - 40 }, 700, 'swing');
        }
      }
    },
  },
  watch: {
    step(newStep) {
      const stepBar = document.querySelector('[data-js="step-class"]');
      if (stepBar) {
        stepBar.dataset.step = newStep;
      }
      this.updateShowJobCard();
      this.scrollToTop();
    },
  },
};
